.body-container {
   height: 100%;
   width: auto;
   position: relative;

   .body-row {
      padding: 150px 48px 100px 48px;

      .ant-col {
         display: block;
   
         .body-title {
            font-size: 50px;
            color: #13c08c;
            font-family: "Nunito", sans-serif;
            font-weight: 600;
            justify-content: center;
            display: flex;
            align-items: center;

            .body-title-colour {
               display: flex; 
               color: #01385a;
               margin-right: 15px;
            }
         }
      }
      
      .body-banner {
         margin-top: 50px;
         display: flex;
         align-items: center;
         padding: 0px 200px;
         .body-banner-checked {
            display: flex; 
            align-items: top;
            justify-content: center;

            .body-checked-icon {
               height: 40px;
               width: auto;
               margin-right: 5px;
               fill: #01385a;
               stroke-width: 8px;
               stroke: #01385a;
            }
            .body-checked-text {
               font-size: 30px;
               color: #003B4D;
               font-family: "Nunito", sans-serif;
               font-weight: 400;
               line-height: 35px;
            }
         }
      }
   }

   .body-row-card-intro {
      padding: 40px 250px;
      width: 100%;
      display: flex; 
      justify-content: center;
      position: relative;
      overflow: hidden;

      .loop-background {
         position: absolute;
         top: 0;
         width: auto;
         height: 50vh;
         z-index: 0;
         margin-top: -1rem;
      }

      .ant-col {
         display: flex;
         justify-content: space-between;
         position: relative;
         z-index: 1;
         padding: 0px;

         .body-card {
            background-color: transparent;
            width: 100%;
            border: 0px;

            .ant-card-head {
               min-height: 35px;
               justify-content: center;
               div {
                  div {
                     .body-cards-number-container {
                        display: flex; 
                        align-items: center;
                     }

                  }
               }
            }

            .ant-card-cover {
               display: flex; 
               justify-content: center;
               margin-top: -10px;
               
               .body-solar-panels-card {
                  border-radius: 50%;
                  height: auto;
                  width: 40%;
               }
            }

            .ant-card-body {
               padding: 5px 30px 40px 30px;
               .ant-card-meta {
                  text-align: center;
                  .ant-card-meta-title {
                     color: #01385a;
                     font-family: "Nunito", sans-serif;
                     font-weight: 600;
                     font-size: 28px;
                  }

                  .ant-card-meta-description {
                     color: #01385a;
                     font-family: "Nunito", sans-serif;
                     font-weight: 400;
                     font-size: 22px;
                     margin-top: 0px;
                     padding: 0 60px;
                  }
               }
            }
         }
      }         
   }

   // BODY ROW BENEFITS CARD
   .body-row-card-benefits {
      width: 100vw;
      height: 130vh;
      display: flex; 
      justify-content: center;
      position: relative;
      overflow: hidden;
      margin: 180px 0;
      background-color: #b6dbd45d;

      .benefits-bg-img {
         position: absolute;
         z-index: 0;
         height: 130vh;
         width: 100vw;
         filter: opacity(.4)
      }

      .ant-col {
         display: flex;
         justify-content: center;
         position: relative;
         z-index: 1;


         .body-card-benefits {
            background-color: transparent;
            width: 100%;
            border: none;
            justify-content: center;
            border-radius: 50px;
            background-color: transparent;
            height: 80vh;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
            border: 2px solid #01385a;
            background-color: #b6dbd4a1;
            margin: 200px 300px;
            
            .ant-card-cover {
               display: flex; 
               justify-content: center;
               
               .body-solar-panels-card {
                  border-radius: 50%;
                  height: 50%;
                  width: 50%;
               }
            }

            .ant-card-body {
               border: none;
               .ant-card-meta {
                  text-align: center;

                  .ant-card-meta-title {
                     color: #01385a;
                     font-family: "Nunito", sans-serif;
                     font-weight: 600;
                     font-size: 40px;
                     margin-top: 20px;
                  }

                  .ant-card-meta-description {
                     color: #01385a;
                     font-family: "Nunito", sans-serif;
                     font-weight: 400;
                     font-size: 17px;
                     margin-top: 20px;
                     padding: 10px 50px;

                     .ant-row {
                        display: flex;
                        align-items: center;

                        .body-card-benefits-checked {
                           display: flex; 
                           align-items: top;

                           .body-checked-round-icon {
                              height: 40px;
                              width: auto; 
                              fill: #01385a;
                              margin-right: 10px;
                           }

                           .body-checked-round-text {
                              color: #01385a; 
                              font-family: "Nunito", sans-serif;
                              font-weight: 400;
                              font-size: 20px;
                              height: 35px;
                           }
                        }
                     }
                  }
               }

               .body-card-benefits-content {
                  padding: 10px 80px;
                  display: block; 
                  align-items: top;
                  width: 100%;

                  .body-card-benefits-content-text {
                     color: #01385a;
                     font-family: "Nunito", sans-serif;
                     font-weight: 600;
                     font-size: 26px;
                     text-align: center;
                  }

                  .ant-tabs {
                     margin-top: 30px;
                     .ant-tabs-nav {
                        border-bottom: 0px solid black;
                        .ant-tabs-nav-wrap {
                           border-bottom: 1px solid #01385aa5;
                           .ant-tabs-nav-list {
                              .ant-tabs-tab {
                                 color: #01385a;
                                 font-family: "Nunito", sans-serif;
                                 font-weight: 400;
                                 font-size: 18px;
                                 text-align: center;
                                 border-top-right-radius: 5px;
                                 border-top-left-radius: 5px;
                                 background-color: #b6dbd4ce;
                                 padding: 5px 30px;
                                 margin: 0 0 0 5px;

                                 .ant-tabs-tab-btn {
                                    color: #01385a;
                                 }
                              }

                              .ant-tabs-tab-active {
                                 color: #01385a;
                                 font-family: "Nunito", sans-serif;
                                 font-weight: 400;
                                 font-size: 18px;
                                 text-align: center;
                                 border-top-right-radius: 5px;
                                 border-top-left-radius: 5px;
                                 background-color: #b6dbd4ce;
                                 padding: 5px 30px;
                                 margin: 0 0 0 5px;

                                 .ant-tabs-tab-btn {
                                    color: #01385a;
                                 }
                              }


                              .ant-tabs-ink-bar {
                                 height: 0px;
                              }
                           }
                        }
                     }

                     .ant-tabs-content-holder {
                        .ant-tabs-content {
                           .ant-tabs-tabpane {
                              .tab-ownership-container {
                                 .tab-ownership-row-content {
                                    .tab-ownership-col-container {
                                       .tab-ownership-col-text {
                                          display: block;
                                          width: 100%;
                                          padding-right: 50px;
                                          
                                          h3 {
                                             color: #01385a;
                                             font-family: "Nunito", sans-serif;
                                             font-weight: 600;
                                             font-size: 25px;
                                             text-align: left;
                                          }

                                          p {
                                             color: #01385a;
                                             font-family: "Nunito", sans-serif;
                                             font-weight: 400;
                                             font-size: 20px;
                                             text-align: left;
                                             margin-top: 10px;
                                             line-height: 45px;
                                          }

                                          .ant-btn {
                                             width: 200px;
                                             height: auto;
                                             border-radius: 50px;
                                             padding: 8px 30px;
                                             background-color: #d7faf3;
                                             border: 2px solid #01385a;
                                             float: right;
                                             span {
                                                color: #01385a;
                                                font-family: "Nunito", sans-serif;
                                                font-weight: 600;
                                                font-size: 14px;
                                             }
                                             
                                             &:hover {
                                                background-color: #01385a;
                                                border: 2px solid #0180b3;
                                                span {
                                                   color: white;
                                                   font-family: "Nunito", sans-serif;
                                                   font-weight: 600;
                                                   font-size: 14px;
                                                }
                                             }
                                          }
                                       }

                                       .ant-col {
                                          padding-top: 15px;
                                          svg {
                                             height: 280px;
                                             width: auto;
                                          }

                                          .tab-technology-img {
                                             height: 300px; 
                                             width: auto;
                                             object-fit: contain;
                                          }

                                          .tab-community-img {
                                             height: 300px; 
                                             width: auto;
                                             object-fit: contain;
                                          }
                                       }
                                    }
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
      }         
   }

   // BODY ROW PROJECTS INTRODUCTION
   .body-row-project-container {
      padding: 50px 300px 250px 300px;
      width: 100%;
      display: block; 

      .ant-col-project-title {
         margin-bottom: 100px;
         .body-card-title {
            font-size: 50px;
            color: #01385a;
            font-family: "Nunito", sans-serif;
            font-weight: 600;
            justify-content: center;
            display: flex;
            align-items: center;
         }
      }

      .ant-col-solar-project {
         display: flex;
         justify-content: flex-end;
         align-items: center;

         .body-row-project-title {
            font-family: "Nunito", sans-serif;
            font-weight: 600;
            font-size: 50px;
            text-align: center;
            margin-bottom: 100px;
            display: flex;
            align-items: center;

            .body-project-title-color {
               display: flex; 
               color: #01385a;
               margin-left: 15px;
            }
         }

         .body-card-project {
            background-color: transparent;
            border: 0px;
            width: 90%;

            .ant-card-head {
               padding: 0px;
               border: 0px;
               .ant-card-head-wrapper {
                  .ant-card-head-title {
                     .body-card-project-text {
                        margin-bottom: 40px;

                        .body-card-project-phase {
                           font-family: "Nunito", sans-serif;
                           font-weight: 600;
                           font-size: 15px;
                           color: #01385a;
                           margin-bottom: 10px;
                        }

                        .body-card-project-title {
                           font-family: "Nunito", sans-serif;
                           font-weight: 600;
                           font-size: 30px;
                           color: #FDA51E;
                           margin-bottom: 20px;
                        }
                        
                        .body-card-project-intro {
                           font-family: "Nunito", sans-serif;
                           font-weight: 400;
                           font-size: 20px;   
                           text-wrap: wrap;
                           height: 130px;
                           color: #01385a;
                        }
                     }

                     .body-card-project-benefits {
                        .body-card-project-point {
                           display: flex;
                           align-items: center;
                           margin-bottom: 20px;

                           .body-card-point-icon {
                              height: 50px;
                              width: auto; 
                              display: flex; 
                              justify-content: center;
                              fill: #FDA51E;
                              margin-right: 5px;
                              padding-top: 5px;
                           }

                           h4 {
                              font-family: "Nunito", sans-serif;
                              font-weight: 600;
                              font-size: 22px;
                              text-wrap: wrap;
                              color: #01385a;
                           }
                        }
                     }
                  }
               }
            }

            .ant-card-body {
               padding: 0px;
               .ant-card-meta {
                  .ant-card-meta-detail {
                     .ant-card-meta-description {
                        .body-card-solar-metrics {
                           .ant-col {
                              display: flex;
                              width: 40%;
                              .ant-col-solar-metric {
                                 .solar-metric-sphere {
                                    display: inline-block;
                                    width: 40px;
                                    height: 40px;
                                    margin: 0;
                                    border-radius: 50%;
                                    position: relative;
                                    background: radial-gradient(circle at 50% 120%, #f9af3f, #f6a52c 10%, #ed9715 80%, #b96f00 100%);

                                    .solar-metric-shadow {
                                       position: absolute;
                                       width: 100%;
                                       height: 100%;
                                       background: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 50%);
                                       -webkit-transform: rotateX(90deg) translateZ(-150px);
                                       -moz-transform: rotateX(90deg) translateZ(-150px);
                                       -ms-transform: rotateX(90deg) translateZ(-150px);
                                       -o-transform: rotateX(90deg) translateZ(-150px);
                                       transform: rotateX(90deg) translateZ(-150px);
                                       z-index: -1;
                                    }

                                    .energy-metric-icon {
                                       position: absolute;
                                       height: 25px;
                                       width: auto;
                                       fill: white;
                                       top: 50%;
                                       left: 50%;
                                       transform: translate(-50%, -50%);
                                    }

                                    .impact-metric-icon {
                                       position: absolute;
                                       height: 45px;
                                       width: auto;
                                       fill: white;
                                       top: 55%;
                                       left: 50%;
                                       transform: translate(-50%, -50%);
                                    }

                                    
                                    .time-metric-icon {
                                       position: absolute;
                                       height: 40px;
                                       width: auto;
                                       fill: white;
                                       top: 55%;
                                       left: 50%;
                                       transform: translate(-50%, -50%);
                                    }
                                 }

                                 .solar-metric-sphere:before {
                                    content: "";
                                    position: absolute;
                                    top: 1%;
                                    left: 5%;
                                    width: 90%;
                                    height: 90%;
                                    border-radius: 50%;
                                    background: radial-gradient(circle at 50% 0px, #ffffff, rgba(255, 255, 255, 0) 58%);
                                    filter: blur(1px);
                                    z-index: 2;
                                 }
                              }

                              .ant-col-learn-more-btn {
                                 margin-left: 50%;
                                 .solar-metric-learn-btn {
                                    width: 100%;
                                    height: auto;
                                    color: white;
                                    background-color: #FDA51E;
                                    padding: 1px 60px;
                                    text-align: center;
                                    border-radius: 50px;
                                    border: 2px solid #FDA51E;
                                    margin: 0px auto;
                                    font-size: 19px;
                                    font-family: "Nunito", sans-serif;
                                    font-weight: 600;
                                    display: flex;
                                    align-items: center;

                                       .ant-btn-icon {
                                          height: 30px;
                                          width: auto;
                                          fill: white;
                                          padding-top: 5px;
                                          margin-left: 5px;
                                       }
                                    
                                       &:hover {
                                          background-color: #01385a;
                                          border: 2px solid #01385a;
                                          color: white;
                                    
                                          .ant-btn-icon {
                                             fill: white;
                                          }
                                       }
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
         .ant-col-solar-img {
            display: flex;
            justify-content: flex-end;
            
            .body-card-project-img {
               height: 500px;
               width: auto;
               border-radius: 50%;
               background-repeat: no-repeat !important;
               background-size: cover !important;
               border: 2px solid #ee9208;
               background: url("../../../public/home/background-card.png");
               filter: drop-shadow(0 0 2rem #fedf89);
            }
         }
      }        
      
      .ant-col-hydro-project {
         display: flex;
         justify-content: flex-end;
         align-items: center;
         margin: 200px 0px 200px 0px;

         .body-row-project-title {
            font-family: "Nunito", sans-serif;
            font-weight: 600;
            font-size: 45px;
            text-align: center;
            margin-bottom: 100px;
            display: flex;
            align-items: top;

            .body-project-title-color {
               display: flex; 
               color: #01385a;
               margin-left: 15px;
            }
         }

         .body-card-project {
            background-color: transparent;
            border: 0px;
            width: 90%;

            .ant-card-head {
               padding: 0px;
               border: 0px;
               .ant-card-head-wrapper {
                  .ant-card-head-title {
                     .body-card-project-text {
                        margin-bottom: 40px;
                        color: #01385a;

                        .body-card-project-phase {
                           font-family: "Nunito", sans-serif;
                           font-weight: 600;
                           font-size: 15px;
                           color: #01385a;
                           margin-bottom: 10px;
                        }

                        .body-card-project-title {
                           font-family: "Nunito", sans-serif;
                           font-weight: 600;
                           font-size: 30px;
                           color: #0180b3;
                           margin-bottom: 20px;
                        }
                        
                        .body-card-project-intro {
                           font-family: "Nunito", sans-serif;
                           font-weight: 400;
                           font-size: 20px;   
                           text-wrap: wrap;
                           height: 150px;
                           color: #01385a;
                        }
                     }

                     .body-card-project-benefits {
                        .body-card-project-point {
                           display: flex;
                           align-items: center;
                           margin-bottom: 20px;

                           .body-card-point-icon {
                              height: 50px;
                              width: auto; 
                              display: flex; 
                              align-items: center;
                              justify-content: center;
                              fill: #0180b3;
                              margin-right: 15px;
                              padding-top: 5px;
                           }

                           h4 {
                              font-family: "Nunito", sans-serif;
                              font-weight: 600;
                              font-size: 22px;
                              text-wrap: wrap;
                              color: #01385a;
                           }
                        }
                     }
                  }
               }
            }

            .ant-card-body {
               padding: 0px;
               .ant-card-meta {
                  .ant-card-meta-detail {
                     .ant-card-meta-description {
                        .body-card-solar-metrics {
                           .ant-col {
                              display: flex;
                              width: 40%;
                              .ant-col-solar-metric {
                                 .solar-metric-sphere {
                                    display: inline-block;
                                    width: 40px;
                                    height: 40px;
                                    margin: 0;
                                    border-radius: 50%;
                                    position: relative;
                                    background: radial-gradient(circle at 50% 120%, #0c99d1, #0180b3 10%, #0180b3 80%, #01263d 100%);

                                    .solar-metric-shadow {
                                       position: absolute;
                                       width: 100%;
                                       height: 100%;
                                       background: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 50%);
                                       -webkit-transform: rotateX(90deg) translateZ(-150px);
                                       -moz-transform: rotateX(90deg) translateZ(-150px);
                                       -ms-transform: rotateX(90deg) translateZ(-150px);
                                       -o-transform: rotateX(90deg) translateZ(-150px);
                                       transform: rotateX(90deg) translateZ(-150px);
                                       z-index: -1;
                                    }

                                    .energy-metric-icon {
                                       position: absolute;
                                       height: 25px;
                                       width: auto;
                                       fill: white;
                                       top: 50%;
                                       left: 50%;
                                       transform: translate(-50%, -50%);
                                    }

                                    .impact-metric-icon {
                                       position: absolute;
                                       height: 45px;
                                       width: auto;
                                       fill: white;
                                       top: 55%;
                                       left: 50%;
                                       transform: translate(-50%, -50%);
                                    }

                                    
                                    .time-metric-icon {
                                       position: absolute;
                                       height: 40px;
                                       width: auto;
                                       fill: white;
                                       top: 55%;
                                       left: 50%;
                                       transform: translate(-50%, -50%);
                                    }
                                 }

                                 .solar-metric-sphere:before {
                                    content: "";
                                    position: absolute;
                                    top: 1%;
                                    left: 5%;
                                    width: 90%;
                                    height: 90%;
                                    border-radius: 50%;
                                    background: radial-gradient(circle at 50% 0px, #ffffff, rgba(255, 255, 255, 0) 58%);
                                    filter: blur(1px);
                                    z-index: 2;
                                 }
                              }

                              .ant-col-learn-more-btn {
                                 margin-left: 50%;
                                 .solar-metric-learn-btn {
                                    width: 100%;
                                    height: auto;
                                    color: white;
                                    background-color: #0180b3  ;
                                    padding: 1px 60px;
                                    text-align: center;
                                    border-radius: 50px;
                                    border: 2px solid #0180b3;
                                    margin: 0px auto;
                                    font-size: 19px;
                                    font-family: "Nunito", sans-serif;
                                    font-weight: 600;
                                    display: flex;
                                    align-items: center;

                                       .ant-btn-icon {
                                          height: 30px;
                                          width: auto;
                                          fill: white;
                                          padding-top: 5px;
                                          margin-left: 5px;
                                       }
                                    
                                       &:hover {
                                          background-color: #01385a;
                                          border: 2px solid #01385a;
                                          color: white;
                                    
                                          .ant-btn-icon {
                                             fill: white;
                                          }
                                       }
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
         }

         .ant-col-hydro-img {
            display: flex;
            justify-content: flex-start;
            
            .body-card-project-img {
               height: 500px;
               width: auto;
               border-radius: 50%;
               border: 2px solid #0180b3;
               background-repeat: no-repeat !important;
               background-size: cover !important;
               background: url("../../../public/home/background-card.png");
               filter: drop-shadow(0 0 0.6rem #2EF5FF);
            }
         }
      }  


      .ant-col-forest-project {
         display: flex;
         justify-content: flex-end;
         align-items: center;

         .body-row-project-title {
            font-family: "Nunito", sans-serif;
            font-weight: 600;
            font-size: 50px;
            text-align: center;
            margin-bottom: 200px;
            display: flex;
            align-items: center;

            .body-project-title-color {
               display: flex; 
               color: #01385a;
               margin-left: 15px;
            }
         }

         .body-card-project {
            background-color: transparent;
            border: 0px;
            width: 90%;

            .ant-card-head {
               padding: 0px;
               border: 0px;
               .ant-card-head-wrapper {
                  .ant-card-head-title {
                     .body-card-project-text {
                        margin-bottom: 40px;

                        .body-card-project-phase {
                           font-family: "Nunito", sans-serif;
                           font-weight: 600;
                           font-size: 15px;
                           color: #01385a;
                           margin-bottom: 10px;
                        }

                        .body-card-project-title {
                           font-family: "Nunito", sans-serif;
                           font-weight: 600;
                           font-size: 30px;
                           color: #13c08c;
                           margin-bottom: 20px;
                        }
                        
                        .body-card-project-intro {
                           font-family: "Nunito", sans-serif;
                           font-weight: 400;
                           font-size: 20px;   
                           text-wrap: wrap;
                           height: 150px;
                           color: #01385a;
                        }
                     }

                     .body-card-project-benefits {
                        .body-card-project-point {
                           display: flex;
                           align-items: center;
                           margin-bottom: 20px;

                           .body-card-point-icon {
                              height: 50px;
                              width: auto; 
                              display: flex; 
                              align-items: top;
                              justify-content: center;
                              fill: #13c08c;
                              margin-right: 5px;
                              padding-top: 5px;
                           }

                           h4 {
                              font-family: "Nunito", sans-serif;
                              font-weight: 600;
                              font-size: 22px;
                              text-wrap: wrap;
                              color: #01385a;
                           }
                        }
                     }
                  }
               }
            }

            .ant-card-body {
               padding: 0px;
               .ant-card-meta {
                  .ant-card-meta-detail {
                     .ant-card-meta-description {
                        .body-card-solar-metrics {
                           .ant-col {
                              display: flex;
                              width: 40%;
                              .ant-col-solar-metric {
                                 .solar-metric-sphere {
                                    display: inline-block;
                                    width: 40px;
                                    height: 40px;
                                    margin: 0;
                                    border-radius: 50%;
                                    position: relative;
                                    background: radial-gradient(circle at 50% 120%, #13c08c, #0bb481 10%, #07bb85 80%, #017a56 100%);

                                    .solar-metric-shadow {
                                       position: absolute;
                                       width: 100%;
                                       height: 100%;
                                       background: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 50%);
                                       -webkit-transform: rotateX(90deg) translateZ(-150px);
                                       -moz-transform: rotateX(90deg) translateZ(-150px);
                                       -ms-transform: rotateX(90deg) translateZ(-150px);
                                       -o-transform: rotateX(90deg) translateZ(-150px);
                                       transform: rotateX(90deg) translateZ(-150px);
                                       z-index: -1;
                                    }

                                    .energy-metric-icon {
                                       position: absolute;
                                       height: 25px;
                                       width: auto;
                                       fill: white;
                                       top: 50%;
                                       left: 50%;
                                       transform: translate(-50%, -50%);
                                    }

                                    .impact-metric-icon {
                                       position: absolute;
                                       height: 45px;
                                       width: auto;
                                       fill: white;
                                       top: 55%;
                                       left: 50%;
                                       transform: translate(-50%, -50%);
                                    }

                                    
                                    .time-metric-icon {
                                       position: absolute;
                                       height: 40px;
                                       width: auto;
                                       fill: white;
                                       top: 55%;
                                       left: 50%;
                                       transform: translate(-50%, -50%);
                                    }
                                 }

                                 .solar-metric-sphere:before {
                                    content: "";
                                    position: absolute;
                                    top: 1%;
                                    left: 5%;
                                    width: 90%;
                                    height: 90%;
                                    border-radius: 50%;
                                    background: radial-gradient(circle at 50% 0px, #ffffff, rgba(255, 255, 255, 0) 58%);
                                    filter: blur(1px);
                                    z-index: 2;
                                 }
                              }

                              .ant-col-learn-more-btn {
                                 margin-left: 50%;
                                 .solar-metric-learn-btn {
                                    width: 100%;
                                    height: auto;
                                    color: white;
                                    background-color: #13c08c  ;
                                    padding: 1px 60px;
                                    text-align: center;
                                    border-radius: 50px;
                                    border: 2px solid #13c08c;
                                    margin: 0px auto;
                                    font-size: 19px;
                                    font-family: "Nunito", sans-serif;
                                    font-weight: 600;
                                    display: flex;
                                    align-items: center;

                                       .ant-btn-icon {
                                          height: 30px;
                                          width: auto;
                                          fill: white;
                                          padding-top: 5px;
                                          margin-left: 5px;
                                       }
                                    
                                       &:hover {
                                          background-color: #01385a;
                                          border: 2px solid #01385a;
                                          color: white;
                                    
                                          .ant-btn-icon {
                                             fill: white;
                                          }
                                       }
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
         .ant-col-solar-img {
            display: flex;
            justify-content: flex-end;
            
            .body-card-project-img {
               height: 500px;
               width: auto;
               border-radius: 50%;
               border: 2px solid #0ba073;
               background-repeat: no-repeat !important;
               background-size: cover !important;
               background: url("../../../public/home/background-card.png");
               filter: drop-shadow(0 0 0.6rem #13c08c);
            }
         }
      }  
   }
}