
.landing-container {
   height: 75vh;
   width: auto;
   background-repeat: no-repeat !important;
   background-size: cover !important;
   background-color: #d7f1ed;
   position: relative;
   background-position: center 105%;
   filter: opacity(1);
   z-index: 0;

   .landing-row {
      padding: 50px 200px;
      z-index: 4;
      .ant-col-title {
         width: 100%;
         display: block; 
         margin-top: 10%;
         text-align: center;

         .landing-title {
            font-size: 55px;
            color: #01385a;
            font-family: "Nunito", sans-serif;
            font-weight: 600;
            margin-bottom: 40px;
            text-align: center;
         }

         .landing-subtitle {
            font-size: 40px;
            // color: #98d9ff;
            color: #01385a;
            font-family: "Nunito", sans-serif;
            font-weight: 400;
            margin-bottom: 50px;
            line-height: 80px;
            text-align: center;
            padding: 0 100px;
         }
      }
   }


   .landing-waves-svg {
      min-height: 100px;
      max-height: 150px;
      width: 100%;

      .landing-waves_svg__wave_svg__parallax > use {
         animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
      }

      @keyframes move-forever {
         0% {
            transform: translate3d(-90px, 0px, 0px);
         }
      
         100% {
            transform: translate3d(85px, 0px, 0px);
         }
      }

      .landing-waves_svg__wave_svg__parallax > use:first-child {
         animation-delay: -2s;
         animation-duration: 7s;
      }

      .landing-waves_svg__wave_svg__parallax > use:nth-child(2) {
         animation-delay: -3s;
         animation-duration: 10s;
      }

      .landing-waves_svg__wave_svg__parallax > use:nth-child(3) {
         animation-delay: -4s;
         animation-duration: 13s;
      }

      .landing-waves_svg__wave_svg__parallax > use:nth-child(3) {
         animation-delay: -5s;
         animation-duration: 20s;
      }
 
   }
}
